import { template as template_d8c4b9c5ca494c0d9aacc8d10dac4292 } from "@ember/template-compiler";
const FKText = template_d8c4b9c5ca494c0d9aacc8d10dac4292(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
