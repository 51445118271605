import { template as template_fa08906e08dc412188d96366afee9838 } from "@ember/template-compiler";
const SidebarSectionMessage = template_fa08906e08dc412188d96366afee9838(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
